<template>
  <div class="addGetRecordBox">
    <NavBar title="充提记录"></NavBar>
    <van-sticky :offset-top="$pxToVw(92)">
      <div class="navBox">
        <span
          v-for="(item, index) of targetList"
          :key="index"
          :class="{ active: targetActive === index }"
          @click="targetActive = index"
        >
          {{ item }}
        </span>
      </div>
    </van-sticky>
    <div class="contentBox flexAuto flexColumn">
      <ListView :get-list="getList" empty="暂无记录" pull-refresh ref="listView">
        <template v-slot="{ data }">
          <Space :height="30"></Space>
          <div class="listBox" v-for="(item, index) of data" :key="index">
            <div class="topBox">
              <span class="title">{{ item.coinName }}</span>
              <span class="status" :class="statusProcess(item.status).c">{{ statusProcess(item.status).t }}</span>
            </div>
            <van-divider />
            <div class="bottomBox">
              <div>
                <span>数量</span>
                <span>{{ $toFixed(item.amount, userAssetsInfo.xiaoShuDian, true) }}</span>
              </div>
              <div>
                <span>地址</span>
                <span class="siteBox">{{ item.toAddress }}</span>
              </div>
              <div v-if="targetActive === 1">
                <span>手续费</span>
                <span>{{ item.fee || 0 }}{{ item.coinName }}</span>
              </div>
              <div>
                <span>时间</span>
                <span>{{ $dayjs(item.createTime).format('YYYY/MM/DD') }}</span>
              </div>
              <div v-if="item.failReason">
                <span>原因</span>
                <span class="danger">{{ item.failReason }}</span>
              </div>
            </div>
          </div>
        </template>
      </ListView>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      targetActive: 0,
      targetList: ['充值记录', '提取记录'],
      list: [],
      userAssetsInfo: {},
    };
  },
  created() {
    if (this.$route.query.active) {
      this.targetActive = Number(this.$route.query.active);
    }
  },
  mounted() {
    this.$store.commit('setBodyColor');
    this.getUserAssetsInfo();
  },
  methods: {
    getUserAssetsInfo() {
      this.$http('post', '/v1/user/userAssetsInfo', {
        coinId: this.$route.query.coinId,
        walletAccountId: this.$store.state.personal.userActive ? 3 : 1,
      }).then(res => {
        this.userAssetsInfo = res.data;
      });
    },
    async getList(page) {
      let url = '';
      if (this.targetActive === 0) {
        url = '/v1/assets/depositRecords';
      }
      if (this.targetActive === 1) {
        url = '/v1/assets/withdrawRecord';
      }
      let res = await this.$http('get', url, {
        page,
        size: 10,
        walletAccountId: this.$store.state.personal.userActive ? 3 : 1,
      });

      return res;
    },
    statusProcess(res) {
      let a = '';
      let g = '';
      let c = '';
      switch (res) {
        case 1:
          a = '进行中';
          g = '提取中';
          c = 'info';
          break;
        case 2:
          a = '已完成';
          g = '提取成功';
          c = 'success';
          break;
        case 3:
          a = '已拒绝';
          g = '提取失败';
          c = 'danger';
          break;
      }
      return {
        t: this.targetActive === 0 ? a : g,
        c,
      };
    },
  },
  watch: {
    targetActive(res) {
      this.$refs.listView.reset();
    },
  },
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.navBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  background: @white;
  border-bottom: 1px solid #ebedf0;
  font-size: 30px;
  font-weight: 400;
  color: #a0adc1;
  > span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active {
    font-weight: 500;
    color: #525298;
  }
}
.contentBox {
  .success {
    color: #00ac8e;
  }
  .danger {
    color: #ee6560;
  }
  .info {
    color: #2f79f4;
  }
  .listBox {
    background: @white;
    border-radius: 15px;
    border: 1px solid #f0f0f0;
    margin-bottom: 30px;
    .topBox {
      padding: 33px 26px 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 30px;
        font-weight: 600;
        color: #2c395b;
      }
      .status {
        font-size: 24px;
        font-weight: 400;
      }
    }
    .bottomBox {
      padding: 11px 32px;
      color: #2c395b;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 59px;
        font-size: 26px;
        font-weight: 400;
        span:first-child {
          margin-right: 80px;
          flex-shrink: 0;
        }
        span {
          word-break: break-all;
        }
      }
    }
  }
}
.siteBox {
  text-align: right;
  line-height: 1.3;
}
</style>
